import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

function ProjectHeader({ children = null, excerpt, title }) {
  const { mobileBackground, desktopBackground } = useStaticQuery(graphql`
    {
      mobileBackground: file(relativePath: { eq: "mobile/project-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 600, layout: CONSTRAINED)
        }
      }
      desktopBackground: file(relativePath: { eq: "project-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1000, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <div className="pt-20 pb-16 relative">
      <div className="absolute mx-auto w-full max-w-screen-2xl left-0 top-0 right-0">
        <div className="ml-auto w-40 h-auto lg:hidden opacity-75">
          <GatsbyImage
            image={mobileBackground.childImageSharp.gatsbyImageData}
            alt=""
          />
        </div>
        <div className="ml-auto w-full max-w-sm h-auto hidden lg:block">
          <GatsbyImage
            image={desktopBackground.childImageSharp.gatsbyImageData}
            alt=""
          />
        </div>
      </div>
      <div className="container relative">
        <div className="max-w-screen-lg">
          <h1 className="font-medium text-4.5xl md:text-5xl leading-tight text-gray-900">
            {title}
          </h1>
          {excerpt && (
            <p className="pt-3 font-normal text-base md:text-2xl text-medium text-gray-800 leading-6 md:leading-8">
              {excerpt}
            </p>
          )}
          {children}
        </div>
      </div>
    </div>
  )
}

export default ProjectHeader
