import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Glide from "@glidejs/glide"
import FeatureProjectCard from "./FeatureProjectCard"

function FeaturedProjects() {
  const data = useStaticQuery(graphql`
    {
      allMdx(
        filter: {
          fields: { collection: { eq: "projects" } }
          frontmatter: { featured: { eq: true } }
        }
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 4
      ) {
        edges {
          node {
            excerpt
            frontmatter {
              title
              slug
              industry
              features
              coverImage {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  `)

  const [slider] = React.useState(new Glide(`.js-glide`))
  React.useEffect(() => {
    slider.mount()

    // cleanup when unmounting component
    return () => slider.destroy()
  }, [slider])

  return (
    <div className="pb-8">
      <div className="bg-gray-100 relative">
        <div className="container relative z-10">
          <div className="js-glide">
            <div
              className="glide__bullets flex py-5 lg:py-8 space-x-1 sm:space-x-4 lg:space-x-6 xl:space-x-8 overflow-hidden"
              data-glide-el="controls[nav]"
            >
              {data.allMdx.edges.map(({ node }, index) => (
                <button
                  className="glide__bullet project-bullet-nav"
                  key={node.frontmatter.slug}
                  data-glide-dir={`=${index}`}
                >
                  <span className="number">{index + 1}.</span>
                  <span className="title">{node.frontmatter.title}</span>
                </button>
              ))}
            </div>
            <div className="glide__track" data-glide-el="track">
              <ul className="glide__slides">
                {data.allMdx.edges.map(({ node }) => (
                  <li className="glide__slide" key={node.frontmatter.slug}>
                    <FeatureProjectCard project={node} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="h-10 pointer-events-none md:absolute md:left-0 md:right-0 md:bottom-0 md:bg-white md:z-0 md:h-16 lg:h-20"></div>
      </div>
    </div>
  )
}

export default FeaturedProjects
