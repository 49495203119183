import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

function ProjectCard({ project }) {
  return (
    <Link
      to={`/projects/${project.frontmatter.slug}/`}
      className="block bg-white border border-gray-200 hover:shadow-md hover:border-white transition-all duration-300 transform hover:-translate-y-2"
      title={project.frontmatter.title}
    >
      <div className="w-full h-0 relative" style={{ paddingBottom: '56.25%' }}>
        <div className="absolute inset-0 flex items-center justify-center">
          <GatsbyImage
            image={project.frontmatter.logo.childImageSharp.gatsbyImageData}
            className="w-40 h-auto block"
            alt={project.frontmatter.title}
          />
        </div>
      </div>
      <div className="px-3">
        <div className="h-px w-full bg-gray-300" />
        <div className="py-3">
          <span className="block text-gray-900 lg:text-lg font-semibold truncate">{project.frontmatter.title}</span>
          <span className="block text-sm text-gray-700">{project.frontmatter.industry.join(', ')}</span>
        </div>
      </div>
    </Link>
  )
}

export default ProjectCard
