import React from "react"
import { Link, graphql } from "gatsby"
import classnames from "classnames"

import Seo from "../components/seo"
import GetStarted from "../components/GetStarted"
import ProjectHeader from "../components/ProjectHeader"
import ProjectCard from "../components/ProjectCard"

import ArrowRight from "../svg/arrow-right.svg"
import FeaturedProjects from "../components/FeaturedProjects"
import Clients from "../components/Clients"

function ProjectPagination({
  currentPage,
  numPages,
  pathPrefix = "/projects/",
}) {
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? pathPrefix : `${pathPrefix}${currentPage - 1}`
  const nextPage = `${pathPrefix}${currentPage + 1}`

  if (numPages === 1) {
    return null
  }

  return (
    <div
      className={classnames("flex lg:justify-start space-x-5 pt-5", {
        "justify-between": !isFirst && !isLast,
        "justify-end": isFirst && !isLast,
      })}
    >
      {!isFirst && (
        <Link
          to={prevPage}
          rel="prev"
          className="inline-flex items-center px-2 py-2 border border-gray-900 text-gray-800 hover:text-gray-100 hover:bg-gray-900 focus:outline-none focus:bg-black transition-all duration-200"
        >
          <ArrowRight className="transform rotate-180 w-6 h-6 fill-current" />
          <span className="px-2 inline-block font-medium text-lg md:text-xl">
            Prev
          </span>
        </Link>
      )}
      {!isLast && (
        <Link
          to={nextPage}
          rel="next"
          className="inline-flex items-center px-2 py-2 border border-gray-900 text-gray-800 hover:text-gray-100 hover:bg-gray-900 focus:outline-none focus:bg-black transition-all duration-200"
        >
          <span className="px-2 inline-block font-medium text-lg md:text-xl">
            Next
          </span>
          <ArrowRight className="w-6 h-6 fill-current" />
        </Link>
      )}
    </div>
  )
}

function Projects({ pageContext, data, ...rest }) {
  const { currentPage, numPages } = pageContext

  if (!data) return <p>Shooooot! No Projects found!</p>
  const isFirst = currentPage === 1

  return (
    <>
      <Seo
        title="Our Work"
        description="We’ve developed innovative digital platforms & powerful marketing solutions in the fields of retail, construction, consumer goods, financia, manufacturing, pharmaceutics, real estate, education, recreation, tourism & travel etc."
      />
      <ProjectHeader
        title="Our Work"
        excerpt="We’ve developed innovative digital platforms & powerful marketing solutions in the fields of retail, construction, consumer goods, financia, manufacturing, pharmaceutics, real estate, education, recreation, tourism & travel etc."
      />
      {isFirst && <FeaturedProjects />}
      <div className="bg-white py-8">
        <div className="container pb-5">
          {isFirst && (
            <div>
              <h3 className="text-2xl md:text-4xl text-gray-900 font-bold pb-4">
                Other Projects
              </h3>
            </div>
          )}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-5">
            {data.allMdx.edges.map(({ node: project }) => {
              return (
                <ProjectCard key={project.frontmatter.slug} project={project} />
              )
            })}
          </div>
          <ProjectPagination currentPage={currentPage} numPages={numPages} />
        </div>
      </div>
      <div className="bg-gray-100">
        <Clients />
      </div>
      <GetStarted />
    </>
  )
}

export default Projects

export const pageQuery = graphql`
  query blogPosts($skip: Int! = 0, $limit: Int! = 1) {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMdx(
      filter: { fields: { collection: { eq: "projects" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          excerpt
          frontmatter {
            title
            slug
            industry
            features
            technologies
            logo {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`
